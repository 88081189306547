import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Primer ViewComponents are routinely deprecated as they mature. The following guides detail performing component upgrades.`}</p>
    <h2>{`Migrating from Primer CSS`}</h2>
    <p>{`Many Primer CSS use cases are supported by Primer ViewComponents.`}</p>
    <p>{`When migrating from Primer CSS classes to ViewComponents, use this mapping to
help guide your implementation. This list includes components currently in
stable status.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Primer CSS Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ViewComponent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Guide`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`State`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/state-label/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::State`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`breadcrumb-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/breadcrumbs/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::Breadcrumbs`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Counter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/counter-label/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::Counter`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Subhead`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/pagehead/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::Subhead`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`blankslate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/blankslate/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::Blankslate`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/css/storybook/?path=/docs/deprecated-tooltip--docs"
            }}><inlineCode parentName="a">{`.tooltipped`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/tooltip/rails/alpha"
            }}><inlineCode parentName="a">{`Primer::Alpha::Tooltip`}</inlineCode></a>{` and other options`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/guides/rails/migration-guides/primer-css-tooltipped"
            }}>{`Migrating from `}<inlineCode parentName="a">{`.tooltipped`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/css/storybook/?path=/docs/deprecated-selectmenu--docs"
            }}><inlineCode parentName="a">{`SelectMenu`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/selectpanel/rails/alpha"
            }}><inlineCode parentName="a">{`Primer::Alpha::SelectPanel`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/guides/rails/migration-guides/primer-css-select-menu"
            }}>{`Migrating from `}<inlineCode parentName="a">{`SelectMenu`}</inlineCode></a></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Upgrading deprecated components`}</h2>
    <p>{`As Primer ViewComponents are updated, there are often breaking changes and
deprecations that require changes which cannot be auto-corrected with Rubocop or
other tools. These guides will walk you through the upgrade process for specific
components.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Deprecated Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Replacement Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Guide`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Primer::ButtonComponent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/button/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::Button`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/guides/rails/migration-guides/primer-button-component"
            }}>{`Upgrade to Primer::Beta::Button`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Primer::LayoutComponent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/layout/rails/alpha"
            }}><inlineCode parentName="a">{`Primer::Alpha::Layout`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/guides/rails/migration-guides/primer-layout-component"
            }}>{`Upgrade to Primer::Alpha::Layout`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Primer::LocalTime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/relative-time/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::RelativeTime`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/guides/rails/migration-guides/primer-local-time"
            }}>{`Upgrade to Primer::Beta::RelativeTime`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Primer::TimeAgoComponent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/relative-time/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::RelativeTime`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/guides/rails/migration-guides/primer-time-ago"
            }}>{`Upgrade to Primer::Beta::RelativeTime`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Primer::Truncate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/truncate/rails/beta"
            }}><inlineCode parentName="a">{`Primer::Beta::Truncate`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/guides/rails/migration-guides/primer-truncate"
            }}>{`Upgrade to Primer::Beta::Truncate`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<details-dialog>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/components/dialog/rails/alpha"
            }}><inlineCode parentName="a">{`Primer::Alpha::Dialog`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/guides/rails/migration-guides/primer-dialog"
            }}>{`Upgrade to Primer::Alpha::Dialog`}</a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      